import { Link } from "gatsby"
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import DomainIcon from '@material-ui/icons/Domain';
import Typography from '@material-ui/core/Typography';
import StorageIcon from '@material-ui/icons/Storage';
import WebIcon from '@material-ui/icons/Web';


const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex',
  // },
  // paper: {
  //   marginRight: theme.spacing(2),
  // },
  submenu:{
    fontSize:14,
    color:'#212529'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function MenuListComposition() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      
      <div>
      <Typography style={{ textAlign:'right', marginRight:20}}>
      <Link to="/category/domains/">
        <Fab
        style={{ backgroundColor:'#3078b5', color:'#fff', marginRight:10, marginTop:15, marginBottom:14 }} 
          variant="extended"
          size="small"
          aria-label="add"
          className="call"
        >
          <DomainIcon className={classes.extendedIcon} />
          Domains
        </Fab>
</Link>

       <Link to="/category/hosting/">
        <Fab
        style={{ backgroundColor:'green', color:'#fff', marginRight:10, marginTop:15, marginBottom:14 }} 
          variant="extended"
          size="small"
          aria-label="add"
          className="call"
        >
          <StorageIcon className={classes.extendedIcon} />
          Hosting
        </Fab>
        </Link>

        <Link to="/category/web-design-tips/">
        <Fab
        style={{ backgroundColor:'#3078b5', color:'#fff', marginRight:10, marginTop:15, marginBottom:14 }} 
          variant="extended"
          size="small"
          aria-label="add"
          className="call"
        >
          <WebIcon className={classes.extendedIcon} />
          Web Design Tips
        </Fab>
        </Link>

        </Typography>

      </div>
    </div>
  );
}