import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Button from '@material-ui/core/Button';
import styles from "./blog.module.css";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  fontSize: '1.75rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    fontWeight: 400,
  },
};
theme.typography.h2 = {
  fontSize: '1.5rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
};


const PostLink = ({ post }) => (
    <ThemeProvider theme={theme}>
    
    
    <Grid item xs={12} sm={6} md={4} style={{ padding:7 }} >
    <div className={styles.latest}>
    <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} alt={post.frontmatter.title} />
    
    <Typography  gutterBottom variant="h6" align="center">
    {post.frontmatter.title}
    </Typography>
    <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />

     
    <Link to={post.frontmatter.path}>
    <p align="right">
    <Button
        variant="contained"
        color="primary"
        endIcon={<DoubleArrowIcon />}
      >
           read more
           </Button>
    </p>
    </Link>
  </div>
    </Grid>
    
    </ThemeProvider>
)

export default PostLink