import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import StorageIcon from '@material-ui/icons/Email';
import WebIcon from '@material-ui/icons/Web';
import DomainIcon from '@material-ui/icons/Domain';
import HomeIcon from '@material-ui/icons/Home';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from "gatsby";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Chmenuicon from "../images/chmenuicon.png" // Tell Webpack this JS file uses this image


import Logo from "../images/logo.png" // Tell Webpack this JS file uses this image
import Mobilelogin from "./submenu/mobilelogin" // Tell Webpack this JS file uses this image
import Loginmenu from "./submenu/loginmenu" // Tell Webpack this JS file uses this image

import Hostingmenu from "./submenu/hostingmenu" // Tell Webpack this JS file uses this image
import Servermenu from "./submenu/servermenu" // Tell Webpack this JS file uses this image
import styled from "styled-components";

const Header = styled.div`
border-top: 1px solid #666;
border-bottom: 2px solid #51db02;
width: 100% !important;
box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
z-index: 1100;
background-color:#18476f;
`

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      display:'none',
    },
  },
//   appBar: {
//     [theme.breakpoints.up('md')]: {
//       width: `calc(100% - ${drawerWidth}px)`,
//       marginLeft: drawerWidth,
//   },
// },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
 
  drawerPaper: {
    width: drawerWidth,
  },
  
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    zIndex:11,
  },
  grow:{
    flexGrow: 1,
  },
  menucolor:{
    color:'#212529',
    fontSize:14,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,

}));

function Mobilemenu(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
    
      <Divider />
      <List style={{ paddingTop:0 }}>
        <Link to="/" className="menucolor" >
        <ListItem button style={{ paddingLeft:7, paddingRight:7, backgroundColor:'#1a5384' }}>
        <ListItemIcon className={classes.listicon}>
          <HomeIcon style={{ fontSize:18, color:'#fff' }}/>
        </ListItemIcon>
        <ListItemText primary="Home" className={classes.menucolor} style={{ color:'#fff'}} />
        <img src={Chmenuicon} alt="chennaihost" />
      </ListItem>
      </Link>

      <Link to="/category/domains/" className="menucolor">
        <ListItem button style={{ paddingLeft:7, paddingRight:7 }}>
        <ListItemIcon className={classes.listicon}>
          <DomainIcon style={{ fontSize:18, color:'#f50057' }}/>
        </ListItemIcon>
        <ListItemText primary="Domains" className={classes.menucolor} />
      </ListItem>
      </Link>

      <Link to="/category/hosting/" className="menucolor">
        <ListItem button style={{ paddingLeft:7, paddingRight:7 }}>
        <ListItemIcon className={classes.listicon}>
          <StorageIcon style={{ fontSize:18, color:'#f50057' }}/>
        </ListItemIcon>
        <ListItemText primary="Hosting" className={classes.menucolor} />
      </ListItem>
      </Link>

      <Link to="/category/web-design-tips/" className="menucolor">
        <ListItem button style={{ paddingLeft:7, paddingRight:7 }}>
        <ListItemIcon className={classes.listicon}>
          <WebIcon style={{ fontSize:18, color:'#f50057' }}/>
        </ListItemIcon>
        <ListItemText primary="Web Design Tips" className={classes.menucolor} />
      </ListItem>
      </Link>

      </List>

    </div>
  );

  return (
   
    <div className={classes.root}>
    
      <CssBaseline />
      
      <AppBar 
      position="fixed" 
      className={classes.appBar}
      style={{ background: '#23649a' }}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

         
  
          <Link to="/"><img src={Logo} alt="chennaihost" style={{ paddingTop:7 }} /></Link>
          
          <div className={classes.grow}></div>
{/* mobile icons */}
          <Hidden mdUp>
       <Mobilelogin/> 
       </Hidden>
       {/* desktop icons */}
       <Hidden smDown>
       <Loginmenu/>  
       </Hidden>

        </Toolbar>
    
       

      </AppBar>
   
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdDown implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      
  

      <main className={classes.content}>
        
      <div className={classes.toolbar} />
      <Hidden smDown>
      <Header>
      <Toolbar >
    
        
    <Link to="/">
    <Typography className="menubutton1" >Home</Typography>
    </Link> 
    <a href="https://www.chennaihost.com/domains-price-list.html" target="_blank" rel="noopener noreferrer">
    <Typography className="menubutton1" >Domain </Typography>
    </a> 
  <Hostingmenu/>
  <Servermenu/>


  <a href="https://www.chennaihost.com/businessemails.html" target="_blank" rel="noopener noreferrer"><Typography className="menubutton1">Business Emails</Typography></a>
  <a href="https://www.chennaihost.com/get-website.html" target="_blank" rel="noopener noreferrer"><Typography className="menubutton1">Get a Web<span style={{ fontSize:9, position:'absolute', top:-4, backgroundColor:'red', padding: theme.spacing(.3), borderRadius:3 }}>On Sale</span>site  </Typography></a>
  <a href="https://www.chennaihost.com/bulksms.html" target="_blank" rel="noopener noreferrer"><Typography className="menubutton1">Bulk SMS</Typography></a>

  <a href="https://www.chennaihost.com/helpvideo.html" target="_blank" rel="noopener noreferrer"><Typography className="menubutton1">Help Videos</Typography></a>
 <a href="https://www.chennaihost.com/contactus.html" target="_blank" rel="noopener noreferrer"><Typography className="menubutton1">Contact Us</Typography></a>

<a href="https://www.chennaihost.com/offers.html" target="_blank" rel="noopener noreferrer">
<Button variant="contained" size="small" color="primary" className={classes.margin} style={{ marginLeft:10 }}>
<LocalOfferIcon className={classes.extendedIcon} />
  Offers
</Button>
</a>

</Toolbar>

</Header>   
</Hidden>
      </main>
    </div>
  );
}

Mobilemenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default Mobilemenu;