/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Payments from "../components/payments";
import Paypal1 from "../components/paypal1";
import Gpay from "../components/gpay";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Header from "./header"
import "./layout.css"
import styles from "./footer.module.css"
import { ListItem } from "@material-ui/core";
import styled from "styled-components";
const Bline = styled.hr`
width: 70px;
border-color: #96cefd;
border-width: thin;
margin-left:0;
`
const Acss = styled.a`
margin-right:5px;
margin-left:5px;
`
const Footerbg = styled.div`
background-color: #18476f;
color: #dad6d6;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
        <Hidden smDown>
          <Footerbg>
          <Container>
          <Box pb={3}>
          <Grid container spacing={2}>
          
          <Grid item xs={12} sm={6} md={3}>
           <Typography  gutterBottom variant="h6">Services</Typography>
           <Bline/>
           <List className={styles.footericon}>
           <a href="https://www.chennaihost.com/domains-price-list.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} />Domain Price List</ListItem></a>
           <a href="https://www.chennaihost.com/cpanel-hosting.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} />Linux (CPanel) Hosting</ListItem></a>
           <a href="https://www.chennaihost.com/windows-web-hosting.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Windows Hosting</ListItem></a>
           <a href="https://www.chennaihost.com/reseller-hosting.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} />Reseller Hosting</ListItem></a>
           <a href="https://www.chennaihost.com/ssl-certificates.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> SSL Certificates</ListItem></a>
           <a href="https://www.chennaihost.com/get-website.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Web Design</ListItem></a>
           <a href="https://www.chennaihost.com/businessemails.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Get Emails</ListItem></a>
             
           </List>
           
           </Grid>

           <Grid item xs={12} sm={6} md={3}>
           <Typography  gutterBottom variant="h6">About Company</Typography>
           <Bline/>
           <List className={styles.footericon}>
           <a href="https://www.chennaihost.com/about-us.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} />About Us</ListItem></a>
           <a href="https://www.chennaihost.com/contactus.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Contact Us</ListItem></a>
           <a href="https://www.chennaihost.com/helpvideo.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Help Videos</ListItem></a>
           <a href="https://www.chennaihost.com/offers.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Latest Offers</ListItem></a>
           <a href="https://www.chennaihost.com/tos.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Terms of Service </ListItem> </a>
           <a href="https://www.chennaihost.com/privacy.html" target="_blank" rel="noopener noreferrer"><ListItem style={{ paddingLeft:0 }}><DoubleArrowIcon style={{ fontSize:16, color:'#1cc71c', marginRight:7 }} /> Privacy Policy</ListItem></a>
           </List>
           </Grid>
           

           <Grid item xs={12} sm={6} md={3}>
           <Typography  gutterBottom variant="h6">We Accept</Typography>
           <Bline/>
           <p>
           We accept all Indian Credit card, Debit card
and Online banking.
</p>

<Payments />
<div style={{paddingTop:10 }}>
<Gpay/>
</div>
<p>We accept all International Currencies via Paypal</p>

<Paypal1 />



           

           </Grid>

           <Grid item xs={12} sm={6} md={3}>
           <Typography  gutterBottom variant="h6">Contact Info</Typography>
           <Bline/>
      
            <h4 style={{ letterSpacing:1}}>SRK IT SOLUTIONS PVT LTD</h4>
<p>
  No.34, 2nd Floor, 1st Main Road,<br/>
Nagappa Nagar, Chromepet,<br/>
Chennai - 600 044, India.
</p>

<p>CIN : U72900TN2012PTC084743</p>
<p>GST : 33AARCS3338M1ZU</p>
             <p>Phone : <PhoneInTalkIcon style={{ fontSize:22, marginRight:7, color:'#1cc71c', position:'relative', top:6 }} /><a href="tel:+914443855355" target="_blank" rel="noopener noreferrer"> +91 44 43855 355</a></p>
             <p>WhatsApp : <WhatsAppIcon style={{ fontSize:22, marginRight:7, color:'#1cc71c', position:'relative', top:6 }} /><a href="tel:+919600123538" target="_blank" rel="noopener noreferrer">+91 96 00 123 538</a></p>
             <p>Social : 
               <Acss href="https://www.facebook.com/chennaihost/" target="_blank" rel="noopener noreferrer"><FacebookIcon style={{ backgroundColor:'#3b5998' }} className={styles.socialicon} /></Acss>
               <Acss href="https://twitter.com/chennaihost2" target="_blank" rel="noopener noreferrer"><TwitterIcon style={{ backgroundColor:'green' }} className={styles.socialicon} /></Acss>
               <Acss href="https://www.youtube.com/channel/UCfIzU8rmAXIrTQNdVaGDLZw" target="_blank" rel="noopener noreferrer"><YouTubeIcon style={{ backgroundColor:'#c4302b' }} className={styles.socialicon} /></Acss>
               </p>
           </Grid>

           </Grid>
           </Box>

          </Container>

          <div style={{ backgroundColor:'#0f314e' }} className={styles.footerbot}>
          <Container>
         <Grid container spacing={2}>
         <Grid item xs={12}>
         <p align="center"> © Copyright {new Date().getFullYear()} <a href="https://www.srkitsolutions.com/" target="_blank" rel="noopener noreferrer" className={styles.srk}>SRK IT SOLUTIONS PVT LTD.</a> All Rights Reserved.
          {` `}</p>
          </Grid>
          </Grid>
          

         
          </Container>
          </div>

          </Footerbg>
         
          </Hidden>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
